.code-block {
    font-family: 'Inconsolata', monospace;
}

.code-block svg {
    padding: 0;
    margin: 0;
    fill: #fff;
}

.code-block code {
    max-width: 100%;
}

/* .code-block div {
    padding: .5rem;
} */