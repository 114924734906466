.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
  color: #0866C6;
}

input:focus, textarea:focus, select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #367bc1;
  ;
}

th:focus {
  outline: none
}

button {
  color: #fff;
  border-radius: .25rem;
  font-weight: 700;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.7rem;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.30s ease-in-out, box-shadow 0.30s ease-in-out;
}

button:focus {
  outline: none
}

.logo {
  height: 5rem;
  margin: .2rem;
}

.link {
  cursor: pointer;
  color: #367bc1
}

.icon {
  margin-right: .8rem;
  padding: .2rem;
}

.CircularProgressbar {
  height: 12rem;
  margin: 2rem auto;
  font-family: 'Rubik'
}

table {
  color: #343a40;
  border-collapse: seperate;
  border-spacing: 0;
  width: 100%;
  margin: 0 1rem 1rem 0;
  background-color: transparent;
  text-align: left;
  font-size: 1.2em;
  text-transform: capitalize;
  letter-spacing: .5px;
}

table th, td {
  padding: .75rem;
  min-width: 8rem;
}

table td {
  border-top: 1px solid #dee2e6;
}

input, textarea, select {
  height: 2rem;
  padding: .4rem .75rem;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(59, 59, 59, 0.75);
  transition: top 0.4s, opacity 0.4s;
}

.modal-main {
  transition: opacity 0.15s linear;
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.sq-fields {
  border: 0;
  margin: 1rem;
  padding: 1rem;
}

.sq-payment-form {
  width: auto;
}

#connect-container {
  position: relative;
  display: flex;
  flex: 1;
  width: 500px;
  height: 700px;
  margin: 0 auto;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;
}

#connect-container:empty {
  display: none;
}

#connect-container-lite {
  position: relative;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;
}

#connect-container-lite:empty {
  display: none;
}